import type { ReactElement } from "react";
import { FormattedMessage, defineMessages, useIntl, type MessageDescriptor } from "react-intl";

import { StyledTextInput } from "common/core/form/text";
import { Select } from "common/core/form/select";
import type { UseFormRegisterReturn } from "common/core/form";

import Styles from "./index.module.scss";

export enum Pronouns {
  SHE_HER = "She/her",
  HE_HIM = "He/him",
  THEY_THEM = "They/them",
  OTHER = "Other",
}

export function getPronounsFormDataForUser({ pronouns: userPronouns }: { pronouns?: Pronouns }) {
  const pronouns = userPronouns
    ? Object.values(Pronouns).includes(userPronouns)
      ? userPronouns
      : Pronouns.OTHER
    : undefined;
  return {
    pronouns,
    customPronouns: userPronouns,
  };
}

export type FormValues = {
  pronouns: Pronouns;
  customPronouns?: string;
};

type HookFormProps = {
  selectedPronouns: Pronouns;
  pronounsProps: UseFormRegisterReturn<"pronouns">;
  customPronounsProps: UseFormRegisterReturn<"customPronouns">;
};

export const PRONOUNS_MAP: Record<
  FormValues["pronouns"],
  { label: ReactElement; ariaLabel?: MessageDescriptor }
> = {
  "She/her": {
    label: <FormattedMessage id="51cc78ba-38eb-4467-bbc1-fd8c22a99c16" defaultMessage="She/her" />,
  },
  "He/him": {
    label: <FormattedMessage id="4ff1e7df-baa5-40ca-b648-6f6e2c6a57a8" defaultMessage="He/him" />,
  },
  "They/them": {
    label: (
      <FormattedMessage id="2242dfb5-f757-4cad-840f-0c9ea5875f89" defaultMessage="They/them" />
    ),
  },
  Other: {
    label: (
      <FormattedMessage
        id="1294af61-4d72-452a-aec4-d551762b4131"
        defaultMessage="Not listed, please specify"
      />
    ),
    ariaLabel: {
      id: "68f57f5e-5341-4af2-b33e-33e272b046c3",
      defaultMessage: "Not listed, please specify your custom pronouns below",
    },
  },
};

export const PREDEFINED_PRONOUNS = Object.keys(PRONOUNS_MAP).map((pronoun) => ({
  value: pronoun,
  label: PRONOUNS_MAP[pronoun as FormValues["pronouns"]].label,
  ariaLabel: PRONOUNS_MAP[pronoun as FormValues["pronouns"]].ariaLabel,
}));

const LABEL_MESSAGES = defineMessages({
  pronouns: {
    id: "9aa7ae92-9340-4c92-b25a-39aeb291e640",
    defaultMessage: "Pronouns (optional)",
  },
  customPronouns: {
    id: "b3b0976a-0c5d-4455-93b2-1f5243f9a120",
    defaultMessage: "Custom pronouns",
  },
});

const PLACEHOLDER_MESSAGES = defineMessages({
  customPronouns: {
    id: "408540de-865a-466b-ad99-fd1dbb0f56bf",
    defaultMessage: "Specify your pronouns",
  },
});

export function PronounsSubHookForm({
  pronounsProps,
  customPronounsProps,
  selectedPronouns,
}: HookFormProps) {
  const intl = useIntl();
  const { onChange, ...otherPronounsProps } = pronounsProps;

  return (
    <>
      <Select
        aria-invalid={false}
        data-automation-id="pronouns-field"
        items={PREDEFINED_PRONOUNS}
        aria-label={intl.formatMessage(LABEL_MESSAGES.pronouns)}
        onChange={(event) => {
          onChange(event);
        }}
        {...otherPronounsProps}
      />
      {selectedPronouns === Pronouns.OTHER && (
        <div className={Styles.customPronouns}>
          <StyledTextInput
            id="customPronouns"
            label={intl.formatMessage(LABEL_MESSAGES.customPronouns)}
            aria-invalid={false}
            placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.customPronouns)}
            {...customPronounsProps}
          />
        </div>
      )}
    </>
  );
}
