import { defineMessages } from "react-intl";
import { useMatch } from "react-router-dom";

const MESSAGES = defineMessages({
  myClosingsLabel: {
    id: "0f64cca9-4be7-4066-a200-38b93d27dae3",
    defaultMessage: "My closings",
  },
  sendAndManageLabel: {
    id: "30466052-9694-4f8e-8c6a-c49989e2e3ec",
    defaultMessage: "Send & manage",
  },
  onDemandLabel: {
    id: "65cdc54f-3c49-41ee-8c1f-973604791384",
    defaultMessage: "On demand",
  },
  meetingsLabel: {
    id: "a1599230-f749-4d45-9118-020ca4023927",
    defaultMessage: "Meetings",
  },
  toolsLabel: {
    id: "ffc92389-d4fe-41d3-aa56-9a2d47bab48e",
    defaultMessage: "Tools",
  },
  orgSettingsLabel: {
    id: "10348fce-f7ca-47dd-ac6f-2bba4435cbe5",
    defaultMessage: "Settings",
  },
  usersLabel: {
    id: "174a66b9-2000-49a9-9f83-13b1fc44bc5c",
    defaultMessage: "Users",
  },
  getStartedLabel: {
    id: "91bb612d-2dec-4814-8e11-f3872bc44dd9",
    defaultMessage: "Get started",
  },
  companyDirectoryLabel: {
    id: "8acdec1b-2cdf-425a-a207-b13ace8bdcde",
    defaultMessage: "Company directory",
  },
  userManagementLabel: {
    id: "f25efb14-b846-4afe-b676-0497709315cf",
    defaultMessage: "User management",
  },
  accessLabel: {
    id: "f7b041c2-c6c9-480d-ba35-8cbc157e6119",
    defaultMessage: "Access",
  },
  nava11yLabel: {
    id: "d9bdeb41-6dbc-45d9-9e28-e689bdc93fd4",
    defaultMessage: "Portal navigation",
  },
  identityCRMLabel: {
    id: "6d19e122-e2fb-482a-933a-e678377c0101",
    defaultMessage: "Identities",
  },
});

export function useIsPathActive(path: string, end = false): boolean {
  return Boolean(useMatch({ path, end }));
}

export const {
  myClosingsLabel,
  sendAndManageLabel,
  onDemandLabel,
  meetingsLabel,
  toolsLabel,
  orgSettingsLabel,
  usersLabel,
  getStartedLabel,
  companyDirectoryLabel,
  userManagementLabel,
  accessLabel,
  nava11yLabel,
  identityCRMLabel,
} = MESSAGES;
